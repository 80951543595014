
<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "AGREGAR" : "EDITAR" }} IMAGEN</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- TITLE -->
        <vs-input label="Titulo" v-model="title" class="mt-5 w-full" name="item-name" v-validate="'required'" />


        <!-- datePicture -->

        <div class="vx-col md:w-1/3 w-full mt-5" v-show="pictureId">
          <img id="retrievePicture" :src="retrievePicture(projectId,pictureId)" alt="content-img" class="responsive rounded-lg">
          <label for="file"> Cargar Imagen</label>
          <form id="uploadFormProject1" enctype="multipart/form-data">
            <input type="file" id="fileUploadPicture" name="fileUploadPicture" v-on:change="uploadPicture(this, projectId, pictureId)">
          </form>
        </div>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData()" >Guardar</vs-button>
      <vs-button class="mr-6" color="danger" @click="deleteData()" @click.stop="$vs.notify({
                      title:'Eliminado',
                      text:'La unidad ha sido eliminada',
                      color:'danger'})" >Eliminar</vs-button>
      <br>
      <br>
      <br>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import EdocService from '@/services/EdocService';
import projectService from '@/services/BOProjectService.js';

import Vue from 'vue'
import VeeValidate from 'vee-validate'

Vue.use(VeeValidate)

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
        if (this.data.title!=undefined) {
          this.isNew=true;
        } else {
          this.isNew=false;
        }
      if(!val) return
        let { id, projectId, title, datePicture } = JSON.parse(JSON.stringify(this.data))
        this.pictureId = id
        this.projectId = projectId
        this.title = title
        this.datePicture = datePicture
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {
      isNew: false,
      id:"",
      projectId: "",
      title: "",
      datePicture: "",
      dataImg: null,

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
           //this.$validator.reset()
           //this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.dataName;
    }
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.id = ""
        this.title = ""
        this.datePicture = ""
    },
    submitData() {
      //this.$validator.validateAll().then(result => {
          //if (this.dataId) {
            const obj = {
              id: this.pictureId,
              pictureId: this.pictureId,
              projectId: this.projectId,
              title: this.title,
              datePicture: this.datePicture,
            }
            if (this.title) {

              if(this.pictureId != null) {
                //console.log(this.id);
                this.$store.dispatch("dataListPicture/updatePicture", obj).then(() => { /*.catch(err => { console.error(err) })*/
                    //console.info('unidad actualizada');
                    this.$vs.notify({
                          title:'Actualizado',
                          text:'La unidad ha sido actualizada y se verá reflejado al refrescar',
                          color:'primary'});
                  })
              } else {
                this.$store.dispatch("dataListPicture/newPicture", obj).then(() => { /*.catch(err => { console.error(err) })*/
                    //console.info('unidad guardada');
                    this.$vs.notify({
                          title:'Guardado',
                          text:'La unidad ha sido guardada y se verá reflejado al refrescar',
                          color:'primary'});
                  })                  
              }
              this.isSidebarActiveLocal = false;
            } else {
                this.$vs.notify({
                  title:'Error',
                  text:'Ha ocurrido un problema guardando el registro',
                  color:'danger'});
            }

            //this.$emit('closeSidebar')
            //this.initValues()
          //}
      //})
    },
    retrievePicture(proj, pictureId) {
      var identifier = "img_"+proj+pictureId;
      var code = process.env.VUE_APP_API_ROOT + EdocService.retrieveImg(identifier);

      //var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BO_PRO_API + projectService.imgResponse(identifier);
      //console.log(code);
      return code;

    },
    uploadPicture: async function (file, proj, pictureId) {
      var identifier = proj+pictureId;
      let formData = new FormData();
      formData.append('file', document.getElementById("fileUploadPicture").files[0]);

      var response = await projectService.imgUpload(identifier, formData);
      //console.log(response); // 10
      if (response) {
        var el = document.getElementById("retrievePicture");
        el.src = this.retrievePicture(identifier);
        this.$vs.notify({
              title:'Actualizado',
              text:'La imagen ha sido cargada con éxito',
              color:'primary'});
      } else {
        this.$vs.notify({
          title:'Error',
          text:'Ha ocurrido un problema con el formato del archivo',
          color:'danger'});
      }
    },
    deleteData() {
      const obj = {
        id: this.pictureId,
        projectId: this.projectId,
      }
      if(this.pictureId != null) {
        this.$store.dispatch("dataListPicture/removePicture", obj)//.catch(err => { console.error(err) })
      } 
      this.$emit('closeSidebar')
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  components: {
    VuePerfectScrollbar,
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
