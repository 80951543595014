/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_PROJECTS(state, projects) {
    state.projects = projects
  },
  UPDATE_PROJECT(state, project) {
    const projectIndex = state.projects.findIndex((p) => p.id == project.id)
    Object.assign(state.projects[projectIndex], project)
  },
  CREATE_PROJECT(state, project) {
    state.project = project;
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.projects.findIndex((p) => p.id == itemId)
    state.projects.splice(ItemIndex, 1)
  },
}
