<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "AGREGAR" : "EDITAR" }} UNIDAD</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- UNITNUMBER -->
        <vs-input label="Unidad" v-model="unitNumber" class="mt-5 w-full" name="item-name" v-validate="'required'" 
        :disabled="isNew" />
        <!-- DESCRIPTION -->
        <vs-input label="Descripción" v-model="description" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- PRICE -->
        <vs-input label="Precio" type="number" v-model="price" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- TYPE -->
        <vs-input-number min="1" max="100" label="Piso:" v-model="floor" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- STATUS -->
        <vs-select autocomplete v-model="status" label="Estado" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in category_choices" />
        </vs-select>

        <!-- TYPOLOGY -->
        <vs-input label="Tipologia" v-model="typology" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- TYPE -->
        <vs-input label="Tipo" v-model="type" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- TOTALSIZE -->
        <vs-input label="Superficia Total" type="number" v-model="totalSize" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- TERRACESIZE -->
        <vs-input label="Superficia Terraza" type="number" v-model="terraceSize" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- INTERIORSIZE -->
        <vs-input label="Superficia Interior" type="number" v-model="interiorSize" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- ROOMS -->
        <vs-input-number min="1" max="100" label="Habitaciones:" v-model="rooms" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- BATHROOMS -->
        <vs-input-number min="0" max="100" label="Baños:" v-model="bathrooms" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- ORIENTATION -->
        <vs-select autocomplete v-model="orientation" label="Orientación" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in corientation_choices" />
        </vs-select>


        <div class="vx-col md:w-1/3 w-full mt-5" v-show="isNew">
          <img id="retrieveImgUnit" :src="retrieveImg(projectId, typology, type)" alt="content-img" class="responsive rounded-lg">

          <!--<vs-button color="primary" type="border" icon="file_copy" @click="retrieveDocument(id)">
            Rut
          </vs-button>-->
          <label for="file">Cargar Imagen de Tipo</label>
          <form id="uploadForm" enctype="multipart/form-data">
            <input type="file" id="fileUpload" name="fileUpload" v-on:change="uploadImg(this, projectId, typology, type)">
          </form>
        </div>
        <div style="margin-bottom: 50px"></div>


      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData()" >Guardar</vs-button>
      <vs-button class="mr-6" color="danger" @click="deleteData()" @click.stop="$vs.notify({
                      title:'Eliminado',
                      text:'La unidad ha sido eliminada y se verá reflejado al refrescar',
                      color:'danger'})" >Eliminar</vs-button>
      <br>
      <br>
      <br>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import EdocService from '@/services/EdocService';
import projectService from '@/services/BOProjectService.js';

import Vue from 'vue'
import VeeValidate from 'vee-validate'
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask)

Vue.use(VeeValidate)

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
        //console.log(val);
        if (this.data.price!=undefined) {
          this.isNew=true;
        } else {
          this.isNew=false;
        }
      if(!val) return
      /*if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
        let { projectId } = JSON.parse(JSON.stringify(this.data))
        this.projectId = projectId
        console.log("this data");
        console.log(JSON.parse(JSON.stringify(this.data)));
      }else {
        let { unitId, projectId, unitNumber, price, currency, floor, description, status, typology, type, totalSize, terraceSize, interiorSize, rooms, bathrooms, orientation } = JSON.parse(JSON.stringify(this.data))
        this.id = unitId
        this.projectId = projectId
        this.unitNumber = unitNumber
        this.price = price
        this.currency = currency
        this.floor = floor
        this.description = description
        this.status = status
        this.typology= typology
        this.type= type
        this.totalSize= totalSize
        this.terraceSize= terraceSize
        this.interiorSize= interiorSize
        this.rooms= rooms
        this.bathrooms= bathrooms
        this.orientation= orientation
        //this.initValues()
        console.log("else");
        console.log(this.data);
      }*/
        let { unitId, projectId, unitNumber, price, currency, floor, description, status, typology, type, totalSize, terraceSize, interiorSize, rooms, bathrooms, orientation } = JSON.parse(JSON.stringify(this.data))
        this.id = unitId
        this.projectId = projectId
        this.unitNumber = unitNumber
        this.price = price
        this.currency = currency
        this.floor = floor
        this.description = description
        this.status = status
        this.typology= typology
        this.type= type
        this.totalSize= totalSize
        this.terraceSize= terraceSize
        this.interiorSize= interiorSize
        this.rooms= rooms
        this.bathrooms= bathrooms
        this.orientation= orientation
        //this.initValues()

      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {
      isNew: false,
      id:"",
      projectId: "",
      unitNumber: null,
      price: null,
      currency: [],
      floor: 1,
      description: "",
      status: "",
      typology: "",
      type: "",
      totalSize:"",
      terraceSize:"",
      interiorSize:"",
      rooms:1,
      bathrooms:1,
      orientation:"",
      dataName: "",
      dataImg: null,

      category_choices: [
        {text:'En espera de pago',value:'AWAITING_BOOKING_PAYMENT'},
        {text:'Reservada',value:'BOOKED'},
        {text:'Disponible',value:'AVAILABLE'},
        {text:'Vendido',value:'SOLD'},
        {text:'Bloqueado',value:'BLOCKED'}
      ],
      corientation_choices: [
        {text:'Norte',value:'Norte'},
        {text:'Sur',value:'Sur'},
        {text:'Oriente',value:'Oriente'},
        {text:'Poniente',value:'Poniente'},
        {text:'NorOriente',value:'NorOriente'},
        {text:'NorPoniente',value:'NorPoniente'},
        {text:'SurOriente',value:'SurOriente'},
        {text:'SurPoniente',value:'SurPoniente'}
      ],

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
           //this.$validator.reset()
           //this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.dataName;
    }
  },
  methods: {
    retrieveImg(proj, typology, type) {
      if (type && type.length < 3) {
        if (typology != 'Estudio') {
          typology = typology.replace(/\D/g, '');
        }
        var identifier = 'img_'+proj+"_"+typology+"_"+type;

        var code = process.env.VUE_APP_API_ROOT + EdocService.retrieveImg(identifier);
        //var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BO_PRO_API + projectService.imgResponse(identifier);
        //console.log(code);
        return code;
      } else {
        return null;
      }
    },
    uploadImg: function (file, proj, typology, type) {

        if (typology != 'Estudio') {
          typology = typology.replace(/\D/g, '');
        }
        var identifier = proj+"_"+typology+"_"+type;
      
      let formData = new FormData();
      formData.append('file', document.getElementById("fileUpload").files[0]);
      //console.log(identifier);
      //console.log(file);
      //const file = event.target.files[0];
      projectService.imgUpload(identifier, formData)
        .then(response => {
          if (response) {
            var el = document.getElementById("retrieveImgUnit");
            el.src = this.retrieveImg(identifier);
            this.$vs.notify({
                  title:'Actualizado',
                  text:'La imagen ha sido cargada con éxito',
                  color:'primary'});
            //alert("Documento cargado con exito");
          } else {
            this.$vs.notify({
              title:'Error',
              text:'Ha ocurrido un problema con el tamaño del archivo',
              color:'danger'});
              }
        })
        .catch(error => {
          this.$log.error('Error calling clientService.listClient :  - ', error);
        });
    },
    initValues() {
      if(this.data.id) return
        this.unitNumber = ""
        this.price = null
        //this.currency = ""
        this.floor = null
        this.description = ""
        this.status = ""
        this.typology= ""
        this.type= ""
        this.totalSize= ""
        this.terraceSize= ""
        this.interiorSize= ""
        this.rooms= null
        this.bathrooms= null
        this.orientation= ""
    },
    submitData() {
      //console.log("submitData");
      //this.$validator.validateAll().then(result => {
          //if (this.dataId) {
            const obj = {
              id: this.id,
              projectId: this.projectId,
              unitNumber: this.unitNumber,
              price: this.price,
              currency: this.currency,
              floor: this.floor,
              description: this.description,
              status: this.status,
              typology : this.typology,
              type: this.type,
              totalSize: this.totalSize,
              terraceSize: this.terraceSize,
              interiorSize: this.interiorSize,
              rooms: this.rooms,
              bathrooms: this.bathrooms,
              orientation: this.orientation
            }
            //console.log(this.unitNumber);
            if (this.unitNumber && 
                this.price && 
                this.description && 
                this.typology && 
                this.type) {
              if(this.id != null) {
                this.$store.dispatch("dataListUnit/updateUnit", obj).then(() => { /*.catch(err => { console.error(err) })*/
                  //console.info('unidad actualizada');
                  this.$vs.notify({
                        title:'Actualizado',
                        text:'La unidad ha sido actualizada y se verá reflejado al refrescar',
                        color:'primary'});
                })
              } else {
                //console.log("new unit");
                this.$store.dispatch("dataListUnit/newUnit", obj).then(() => { /*.catch(err => { console.error(err) })*/
                  //console.info('unidad guardada');
                  this.$vs.notify({
                        title:'Guardado',
                        text:'La unidad ha sido guardada y se verá reflejado al refrescar',
                        color:'primary'});
                })              
              }
              this.isSidebarActiveLocal = false;
            } else {
                this.$vs.notify({
                  title:'Error',
                  text:'Ha ocurrido un problema guardando el registro',
                  color:'danger'});
            }

            //this.$emit('closeSidebar')
            //this.initValues()
          //}
      //})
    },
    deleteData() {
      const obj = {
        id: this.id,
        projectId: this.projectId,
        unitNumber: this.unitNumber,
      }
      if(this.id != null) {
        this.$store.dispatch("dataListUnit/removeUnit", obj)//.catch(err => { console.error(err) })
      } 
      this.$emit('closeSidebar')
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  components: {
    VuePerfectScrollbar,
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
