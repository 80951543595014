/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_UNIT(state, units) {
    state.units = units
  },
  UPDATE_UNIT(state, project) {
    //console.log("update project mutations");
    const projectIndex = state.projects.findIndex((p) => p.id == project.id)
    Object.assign(state.projects[projectIndex], project)
    /*
    const unitIndex = state.units.findIndex((p) => p.unitNumber == unit.unitNumber)
    Object.assign(state.units[unitIndex], unit)*/
  },
  CREATE_UNIT(state, project) {
    //state.project = project;
    const projectIndex = state.projects.findIndex((p) => p.id == project.id)
    Object.assign(state.projects[projectIndex], project)
  },
  REMOVE_UNIT(state, itemId) {
    const ItemIndex = state.units.findIndex((p) => p.id == itemId)
    state.units.splice(ItemIndex, 1)
  },
}
