<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "AGREGAR" : "EDITAR" }} ADICIONAL</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- UNITNUMBER -->
        <vs-input label="Unidad" v-model="unitNumber" class="mt-5 w-full" name="item-name" v-validate="'required'" 
        :disabled="isNew"/>
        <!-- DESCRIPTION -->
        <vs-input label="Descripción" v-model="description" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <!-- PRICE -->
        <vs-input label="Precio" v-model="price" type="number" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- STATUS -->
        <vs-select autocomplete v-model="status" label="Estado" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in category_choices" />
        </vs-select>

        <!-- TYPE -->
        <vs-select autocomplete v-model="type" label="Tipo" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in type_choices" />
        </vs-select>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData()" >Guardar</vs-button>
      <vs-button class="mr-6" color="danger" @click="deleteData()" @click.stop="$vs.notify({
                      title:'Eliminado',
                      text:'La unidad ha sido eliminada',
                      color:'danger'})" >Eliminar</vs-button>
      <br>
      <br>
      <br>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import Vue from 'vue'
import VeeValidate from 'vee-validate'

Vue.use(VeeValidate)

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
        //console.log(val);
        if (this.data.price!=undefined) {
          this.isNew=true;
        } else {
          this.isNew=false;
        }
      if(!val) return
        /*
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
        let { projectId } = JSON.parse(JSON.stringify(this.data))
        this.projectId = projectId
        console.log("this data");
        console.log(JSON.parse(JSON.stringify(this.data)));
      }else {
        let { unitId, projectId, unitNumber, price, currency, description, status, type } = JSON.parse(JSON.stringify(this.data))
        this.id = unitId
        this.projectId = projectId
        this.unitNumber = unitNumber
        this.price = price
        this.currency = currency
        this.description = description
        this.status = status
        this.type= type
        console.log("else");
        //this.initValues()
      }*/

        let { unitId, projectId, unitNumber, price, currency, description, status, type } = JSON.parse(JSON.stringify(this.data))
        this.id = unitId
        this.projectId = projectId
        this.unitNumber = unitNumber
        this.price = price
        this.currency = currency
        this.description = description
        this.status = status
        this.type= type
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {
      isNew: false,
      id:"",
      projectId: "",
      unitNumber: null,
      price: null,
      currency: [],
      floor: 1,
      description: "",
      status: "",
      typology: "",
      type: "",
      totalSize:"",
      terraceSize:"",
      interiorSize:"",
      rooms:1,
      bathrooms:1,
      orientation:"",
      dataName: "",
      dataImg: null,

      category_choices: [
        {text:'En espera de pago',value:'AWAITING_BOOKING_PAYMENT'},
        {text:'Reservada',value:'BOOKED'},
        {text:'Disponible',value:'AVAILABLE'},
        {text:'Vendido',value:'SOLD'}
      ],
      type_choices: [
        {text:'Estacionamiento',value:'parking'},
        {text:'Bodega',value:'warehouse'},
        {text:'Estacinamiento con Bodega',value:'parkingWarehouse'}
      ],
      corientation_choices: [
        {text:'Norte',value:'NORTH'},
        {text:'Sur',value:'SOUTH'},
        {text:'Oriente',value:'EAST'},
        {text:'Poniente',value:'WEST'},
        {text:'NorOriente',value:'NORTH_EAST'},
        {text:'NorPoniente',value:'NORTH_WEST'},
        {text:'SurOriente',value:'SOUTH_EAST'},
        {text:'SurPoniente',value:'SOUTH_WEST'}
      ],

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
           //this.$validator.reset()
           //this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.dataName;
    }
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.unitNumber = ""
        this.price = null
        //this.currency = ""
        this.description = ""
        this.status = ""
        this.type= ""
    },
    submitData() {
      //console.log("submitData");
      //this.$validator.validateAll().then(result => {
          //if (this.dataId) {
            const obj = {
              id: this.id,
              projectId: this.projectId,
              unitNumber: this.unitNumber,
              price: this.price,
              currency: this.currency,
              //floor: this.floor,
              description: this.description,
              status: this.status,
              //typology : this.typology,
              type: this.type,
              //totalSize: this.totalSize,
              //terraceSize: this.terraceSize,
              //interiorSize: this.interiorSize,
              //rooms: this.rooms,
              //bathrooms: this.bathrooms,
              //orientation: this.orientation
            }
            if (this.unitNumber && 
                this.price && 
                this.description) {

            

              if(this.id != null) {
                //console.log("update additional");
                //console.log(this.id);
                this.$store.dispatch("dataListAdditional/updateAdditional", obj).then(() => { /*.catch(err => { console.error(err) })*/
                    //console.info('unidad actualizada');
                    this.$vs.notify({
                          title:'Actualizado',
                          text:'La unidad ha sido actualizada y se verá reflejado al refrescar',
                          color:'primary'});
                  })
              } else {
                //console.log("new unit");
                this.$store.dispatch("dataListAdditional/newAdditional", obj).then(() => { /*.catch(err => { console.error(err) })*/
                    //console.info('unidad guardada');
                    this.$vs.notify({
                          title:'Guardado',
                          text:'La unidad ha sido guardada y se verá reflejado al refrescar',
                          color:'primary'});
                  })                  
              }
              this.isSidebarActiveLocal = false;
            } else {
                this.$vs.notify({
                  title:'Error',
                  text:'Ha ocurrido un problema guardando el registro',
                  color:'danger'});
            }

            //this.$emit('closeSidebar')
            //this.initValues()
          //}
      //})
    },
    deleteData() {
      const obj = {
        id: this.id,
        projectId: this.projectId,
        unitNumber: this.unitNumber,
      }
      if(this.id != null) {
        this.$store.dispatch("dataListAdditional/removeAdditional", obj)//.catch(err => { console.error(err) })
      } 
      this.$emit('closeSidebar')
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  components: {
    VuePerfectScrollbar,
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
