/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import projectAPI from '@/APIs/boRealEstateProjectAPI'

export default {
  listProjects({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      projectAPI.get(url, params)
        .then((response) => {
          commit('SET_PROJECTS', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // Update
  updateProject({ commit }, item) {
    //console.log("update project actions");
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      projectAPI.put(`${item.id}`, item )
        .then((response) => {
          commit('UPDATE_PROJECT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // New
  newProject({ commit }, item) {
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      projectAPI.post(``, item )
        .then((response) => {
          commit('CREATE_PROJECT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Borrar
  removeProject({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      projectAPI.delete(`${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
