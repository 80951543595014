
import projectAPI from '@/APIs/boRealEstateProjectAPI'

const state = {
  pictures: [],
};

const getters = {

};

const actions = {
  /**
   * Llama al servicio de listado de aplicaciones picture picture
   */
  listPicture({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      projectAPI.get(url, params)
        .then((response) => {
          commit('SET_MILESTONES', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // Update
  updatePicture({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      projectAPI.put(`/update-picture/${item.projectId}/${item.pictureId}`, item )
        .then((response) => {
          commit('UPDATE_MILESTONE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Update
  newPicture({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    return new Promise((resolve, reject) => {
      projectAPI.post(`/new-picture/${item.projectId}`, item )
        .then((response) => {
          commit('UPDATE_MILESTONE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Borrar
  removePicture({ commit }, item) {
    return new Promise((resolve, reject) => {
      projectAPI.delete(`/delete-picture/${item.projectId}/${item.id}`)
        .then((response) => {
          commit('REMOVE_MILESTONE', item.id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
  
};

const mutations = {

  SET_MILESTONE(state, pictures) {
    state.pictures = pictures
  },
  UPDATE_MILESTONE(state, picture) {
    const pictureIndex = state.pictures.findIndex((p) => p.id == picture.id)
    Object.assign(state.pictures[pictureIndex], picture)
  },
  REMOVE_MILESTONE(state, itemId) {
    const pictureIndex = state.pictures.findIndex((p) => p.id == itemId)
    state.pictures.splice(pictureIndex, 1)
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
