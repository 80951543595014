/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import projectAPI from '@/APIs/boRealEstateProjectAPI'

export default {
  listUnits({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '/units';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      projectAPI.get(url, params)
        .then((response) => {
          commit('SET_UNITS', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // Update
  updateUnit({ commit }, item) {
    //console.log("update project actions");
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      projectAPI.put(`/updateUnit/${item.projectId}/${item.unitNumber}`, item )
        .then((response) => {
          commit('UPDATE_UNIT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // New
  newUnit({ commit }, item) {
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      projectAPI.post(`/newUnit/${item.projectId}`, item )
        .then((response) => {
          commit('CREATE_UNIT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Borrar
  removeUnit({ commit }, item) {
    return new Promise((resolve, reject) => {
      projectAPI.delete(`/deleteUnit/${item.projectId}/${item.unitNumber}`)
        .then((response) => {
          commit('REMOVE_UNIT', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
