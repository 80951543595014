<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "AGREGAR" : "EDITAR" }} PROYECTO</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Product Image -->
        <template v-if="dataImg">

          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Update Image</vs-button>
            <vs-button type="flat" color="#999" @click="dataImg = null">Remove Image</vs-button>
          </div>
        </template>

        <!-- NAME -->
        <vs-input label="Nombre" v-model="dataName" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- STATUS -->
        <vs-select autocomplete v-model="dataStatus" label="Estado" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in status_choices" />
        </vs-select>


        <!-- ADDRESS -->
        <!--<vs-input label="Direccion" v-model="dataAddress" class="mt-5 w-full" name="item-name" v-validate="'required'" />-->
        <vs-input label="Calle" v-model="dataAddress.street" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="Numero" v-model="dataAddress.number" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="Ciudad" v-model="dataAddress.region" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="Comuna" v-model="dataAddress.city" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="Información Adicional" v-model="dataAddress.aditionalInfo" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- STATUS -->
        <vs-select autocomplete v-model="dataType" label="Tipo" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in project_type" />
        </vs-select>


        <!-- bookingAmount -->
        <vs-input label="Valor de la Reserva" v-model="dataBookingAmount" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- projectFeatures -->
        <vs-textarea v-model="dataProjectFeatures" label="Características del Proyecto" class="mt-5 w-full" ></vs-textarea>

        <!-- unitFeatures -->
        <vs-textarea v-model="dataUnitsFeatures" label="Características de Unidad" class="mt-5 w-full" ></vs-textarea>

        <!-- locationFeatures -->
        <vs-textarea v-model="dataLocationFeatures" label="Características de Ubicación" class="mt-5 w-full" ></vs-textarea>

        <!-- floors -->
        <vs-input label="Pisos" v-model="dataFloors" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <br>
        <vs-divider class="mb-0"></vs-divider>
        Datos de Cotizacion/Reserva
        <!-- quotesNumber -->
        <vs-input label="Cantidad de Cuotas" v-model="dataQuotesNumber" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- footPercentage -->
        <vs-input label="Porcentaje de Pie" v-model="dataFootPercentage" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- quotationNotes -->
        <vs-textarea v-model="dataQuotationNotes" label="Notas de Cotización" class="mt-5 w-full" ></vs-textarea>


        <!-- E-mail -->
        <vs-input label="E-mail" v-model="dataEmail" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- Pago cuoton -->
        <vs-select autocomplete v-model="dataPaymentAlternative" label="Cuoton" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in paymentAlternative_choices" />
        </vs-select>


        <!-- -->

        <!-- projectDaysBooking -->
        <vs-input label="Dias reserva" v-model="dataProjectDaysBooking" class="mt-5 w-full" name="item-name" />

        <br>
        <vs-divider class="mb-0"></vs-divider>
        Datos de Promesa
        <!-- projectBookingParam -->
        <vs-textarea v-model="dataProjectBookingParam" label="Reserva Aceptación" class="mt-5 w-full" ></vs-textarea>

        <!-- -->


        <!-- dataCivilSocietyName -->
        <vs-input label="Sociedad Civil" v-model="dataCivilSocietyName" class="mt-5 w-full" name="item-name" />
        <!-- dataCivilSocietyRut -->
        <vs-input label="Sociedad Civil RUT" v-model="dataCivilSocietyRut" v-mask="'##.###.###-X'" class="mt-5 w-full" name="item-name" />
        <!-- civilSocietyCity -->
        <vs-input label="Ciudad Sociedad Civil" v-model="dataCivilSocietyCity" class="mt-5 w-full" name="item-name" />
        <!-- dataCivilSocietyRepresentativeName1 -->
        <vs-input label="Nombre Representante 1" v-model="dataCivilSocietyRepresentativeName1" class="mt-5 w-full" name="item-name" />
        <!-- dataCivilSocietyRepresentativeRut1 -->
        <vs-input label="RUT Representante 1" v-model="dataCivilSocietyRepresentativeRut1" v-mask="'##.###.###-X'" class="mt-5 w-full" name="item-name" />
        <!-- dataCivilSocietyRepresentativeDetails1 -->
        <vs-input label="Descripcion Representante 1" v-model="dataCivilSocietyRepresentativeDetails1" class="mt-5 w-full" name="item-name" />
        <!-- dataCivilSocietyRepresentativeName2 -->
        <vs-input label="Nombre Representante 2" v-model="dataCivilSocietyRepresentativeName2" class="mt-5 w-full" name="item-name" />
        <!-- dataCivilSocietyRepresentativeRut2 -->
        <vs-input label="RUT Representante 2" v-model="dataCivilSocietyRepresentativeRut2" v-mask="'##.###.###-X'" class="mt-5 w-full" name="item-name" />
        <!-- dataCivilSocietyRepresentativeDetails2 -->
        <vs-input label="Descripcion Representante 2" v-model="dataCivilSocietyRepresentativeDetails2" class="mt-5 w-full" name="item-name" />

        <!-- civilSocietyRepresentativesAddress -->
        <vs-textarea v-model="dataCivilSocietyRepresentativesAddress" label="Dirección Representantes Sociedad Civil" class="mt-5 w-full" ></vs-textarea>
        
        <!-- dataDetailFirstParamArchive -->
        <vs-textarea v-model="dataDetailFirstParamArchive" label="Detalles Primero" class="mt-5 w-full" ></vs-textarea>
        
        <!-- dataDetailBackgroundBuildingInfo -->
        <vs-textarea v-model="dataDetailBackgroundBuildingInfo" label="Antecedentes Edificación" class="mt-5 w-full" ></vs-textarea>
        
        <!-- dataDetailBackgroundBuildingData -->
        <vs-textarea v-model="dataDetailBackgroundBuildingData" label="Detalles Edificio Entidad" class="mt-5 w-full" ></vs-textarea>
        
        <!-- dataDetailThirdParamArchive -->
        <vs-textarea v-model="dataDetailThirdParamArchive" label="Detalles Registro" class="mt-5 w-full hidden" ></vs-textarea>
        <!-- dataDetailInsuranceName -->
        <vs-input label="Aseguradora" v-model="dataDetailInsuranceName" class="mt-5 w-full" name="item-name" />

        <!-- dataDetailWritingData -->
        <vs-textarea v-model="dataDetailWritingData" label="Detalle Personerías" class="mt-5 w-full" ></vs-textarea>

        <!-- dataSignersArray -->
        <vs-input label="Firmantes de Promesa" v-model="dataSignersArray" class="mt-5 w-full" name="item-name" />

        <br>
        <vs-divider class="mb-0"></vs-divider>
        Avance de Proyecto
        <!-- dataDetailInsuranceName -->
        <vs-input label="Progreso de Proyecto" type="number" v-model="dataProjectProgressPercentage" class="mt-5 w-full" name="item-name" />
        <!-- dataDetailInsuranceName -->
        <vs-input label="Fecha inicio de obra" v-model="dataProjectBuildingStart" class="mt-5 w-full" name="item-name" />
        <!-- dataDetailInsuranceName -->
        <vs-input label="Fecha estimada término construcción" v-model="dataProjectBuildingFinish" class="mt-5 w-full" name="item-name" />
        <!-- dataDetailInsuranceName -->
        <vs-input label="Fecha estimada escrituración" v-model="dataProjectEstimatedSigning" class="mt-5 w-full" name="item-name" />
        <!-- dataDetailInsuranceName -->
        <vs-input label="Evento Opcional" v-model="dataProjectBuildingEventNote" class="mt-5 w-full" name="item-name" />
        <!-- dataDetailInsuranceName -->
        <vs-input label="Nota de Evento Opcional" v-model="dataProjectBuildingEventObservation" class="mt-5 w-full" name="item-name" />
        <!-- -->
        <!-- Upload -->
        <!-- <vs-upload text="Upload Image" class="img-upload" ref="fileUpload" /> -->



        <div class="vx-col md:w-1/3 w-full mt-5" v-show="isNew">
          <img id="retrieveImg0" :src="retrieveImg(dataId)" alt="content-img" class="responsive rounded-lg">

          <!--<vs-button color="primary" type="border" icon="file_copy" @click="retrieveDocument(id)">
            Rut
          </vs-button>-->
          <label for="file"> Imagen Principal Proyecto</label>
          <form id="uploadFormProject" enctype="multipart/form-data">
            <input type="file" id="fileUploadProject" name="fileUploadProject" v-on:change="uploadImg(this, dataId)">
          </form>
        </div>

        <div class="vx-col md:w-1/3 w-full mt-5" v-show="isNew">
          <img id="retrieveImg1" :src="retrieveImg(dataId+'-1')" alt="content-img" class="responsive rounded-lg">
          <label for="file"> Imagen Nº 1</label>
          <form id="uploadFormProject1" enctype="multipart/form-data">
            <input type="file" id="fileUploadProject1" name="fileUploadProject1" v-on:change="uploadImgFirst(this, dataId)">
          </form>
        </div>
        <div class="vx-col md:w-1/3 w-full mt-5" v-show="isNew">
          <img id="retrieveImg2" :src="retrieveImg(dataId+'-2')" alt="content-img" class="responsive rounded-lg">
          <label for="file"> Imagen Nº 2</label>
          <form id="uploadFormProject2" enctype="multipart/form-data">
            <input type="file" id="fileUploadProject2" name="fileUploadProject2" v-on:change="uploadImgSecond(this, dataId)">
          </form>
        </div>

        <div class="vx-col md:w-1/3 w-full mt-5" v-show="isNew">
          <img id="retrieveImg3" :src="retrieveImg(dataId+'-3')" alt="content-img" class="responsive rounded-lg">
          <label for="file"> Imagen Nº 3</label>
          <form id="uploadFormProject3" enctype="multipart/form-data">
            <input type="file" id="fileUploadProject3" name="fileUploadProject3" v-on:change="uploadImgThird(this, dataId)">
          </form>
        </div>



        <div class="vx-col md:w-1/3 w-full mt-5" v-show="isNew">
          <label for="file"> Cargar Unidades</label>
          <form id="uploadFormProject3" enctype="multipart/form-data">
            <input type="file" id="fileUploadProjectUnits" name="fileUploadProjectUnits" v-on:change="uploadUnits(this, dataId)">
          </form>
        </div>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData()" >Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import EdocService from '@/services/EdocService';
import projectService from '@/services/BOProjectService.js';

import Vue from 'vue'
import VeeValidate from 'vee-validate'
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask);
Vue.use(VeeValidate)

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
        //console.log(val);
        if (this.data.id!=undefined) {
          this.isNew=true;
        } else {
          this.isNew=false;
        }
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      }else {
        let { id, address, name, type, status, bookingAmount, projectFeatures, unitsFeatures, locationFeatures, floors, quotesNumber, footPercentage, quotationNotes, 
          email,
          projectDaysBooking,
          projectBookingParam,
          paymentAlternative,
          civilSocietyName,
          civilSocietyRut,
          civilSocietyRepresentativeName1,
          civilSocietyRepresentativeRut1,
          civilSocietyRepresentativeDetails1,
          civilSocietyRepresentativeName2,
          civilSocietyRepresentativeRut2,
          civilSocietyRepresentativeDetails2,
          detailFirstParamArchive,
          detailBackgroundBuildingInfo,
          detailBackgroundBuildingData,
          detailThirdParamArchive,
          detailInsuranceName,
          detailWritingData,
          signersArray,
          civilSocietyRepresentativesAddress,
          civilSocietyCity,
          projectProgressPercentage ,
          projectBuildingStart ,
          projectBuildingFinish ,
          projectEstimatedSigning ,
          projectBuildingEventNote ,
          projectBuildingEventObservation  } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataName = name
        this.dataType = type
        this.dataAddress = address
        this.dataFloors = floors
        this.dataQuotesNumber= quotesNumber
        this.dataFootPercentage= footPercentage
        this.dataQuotationNotes= quotationNotes
        this.dataStatus= status
        this.dataBookingAmount= bookingAmount
        this.dataProjectFeatures= projectFeatures
        this.dataUnitsFeatures= unitsFeatures
        this.dataLocationFeatures= locationFeatures
        this.dataEmail= email

        this.dataProjectDaysBooking= projectDaysBooking
        this.dataProjectBookingParam= projectBookingParam

        this.dataPaymentAlternative= paymentAlternative
        this.dataCivilSocietyName= civilSocietyName
        this.dataCivilSocietyRut= civilSocietyRut
        this.dataCivilSocietyRepresentativeName1= civilSocietyRepresentativeName1
        this.dataCivilSocietyRepresentativeRut1= civilSocietyRepresentativeRut1
        this.dataCivilSocietyRepresentativeDetails1= civilSocietyRepresentativeDetails1
        this.dataCivilSocietyRepresentativeName2= civilSocietyRepresentativeName2
        this.dataCivilSocietyRepresentativeRut2= civilSocietyRepresentativeRut2
        this.dataCivilSocietyRepresentativeDetails2= civilSocietyRepresentativeDetails2
        this.dataDetailFirstParamArchive= detailFirstParamArchive
        this.dataDetailBackgroundBuildingInfo= detailBackgroundBuildingInfo
        this.dataDetailBackgroundBuildingData= detailBackgroundBuildingData
        this.dataDetailThirdParamArchive= detailThirdParamArchive
        this.dataDetailInsuranceName= detailInsuranceName
        this.dataDetailWritingData= detailWritingData 
        this.dataCivilSocietyRepresentativesAddress= civilSocietyRepresentativesAddress 
        this.dataCivilSocietyCity= civilSocietyCity 
        this.dataSignersArray= signersArray 

        this.dataProjectProgressPercentage= projectProgressPercentage 
        this.dataProjectBuildingStart= projectBuildingStart 
        this.dataProjectBuildingFinish= projectBuildingFinish 
        this.dataProjectEstimatedSigning= projectEstimatedSigning 
        this.dataProjectBuildingEventNote= projectBuildingEventNote 
        this.dataProjectBuildingEventObservation= projectBuildingEventObservation 
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      isNew: false,
      dataId: null,
      dataName: "",
      dataImg: null,


      dataType: "",
      dataAddress: {},
      dataFloors : "",
      dataQuotesNumber: null,
      dataFootPercentage: 10,
      dataQuotationNotes: "",
      dataStatus: "",
      dataBookingAmount: null,
      dataProjectFeatures: "",
      dataUnitsFeatures: "",
      dataLocationFeatures: "",
      dataEmail: "",

      dataProjectDaysBooking: "",
      dataProjectBookingParam: "",

      dataPaymentAlternative: "",
      dataCivilSocietyName: "",
      dataCivilSocietyRut: "",
      dataCivilSocietyRepresentativeName1: "",
      dataCivilSocietyRepresentativeRut1: "",
      dataCivilSocietyRepresentativeDetails1: "",
      dataCivilSocietyRepresentativeName2: "",
      dataCivilSocietyRepresentativeRut2: "",
      dataCivilSocietyRepresentativeDetails2: "",
      dataDetailFirstParamArchive: "",
      dataDetailBackgroundBuildingInfo: "",
      dataDetailBackgroundBuildingData: "",
      dataDetailThirdParamArchive: "",
      dataDetailInsuranceName: "",
      dataDetailWritingData: "",
      dataCivilSocietyRepresentativesAddress: "",
      dataCivilSocietyCity: "",
      dataSignersArray: "",

      dataProjectProgressPercentage: "",
      dataProjectBuildingStart: "",
      dataProjectBuildingFinish: "",
      dataProjectEstimatedSigning: "",
      dataProjectBuildingEventNote: "",
      dataProjectBuildingEventObservation: "",

      dataPrice: 0,

      status_choices: [
        {text:'Activo',value:'ACTIVE'},
        {text:'Inactivo',value:'INACTIVE'},
      ],
      paymentAlternative_choices: [
        {text:'Si',value:1},
        {text:'No',value:2},
      ],

      project_type: [
        {text:'Departamentos',value:'APARTMENTS'},
        {text:'Casas',value:'HOUSES'},
        {text:'Oficinas',value:'OFFICES'},
        {text:'Mixtos',value:'MIXED'}
      ],
      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
           //this.$validator.reset()
           //this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.dataName;
    }
  },
  methods: {
    retrieveImg(proj) {
      var identifier = "img_"+proj;
      var code = process.env.VUE_APP_API_ROOT + EdocService.retrieveImg(identifier);

      //var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BO_PRO_API + projectService.imgResponse(identifier);
      //console.log(code);
      return code;

    },
    retrieveImgSecond(proj) {
      var identifier = "img_"+proj+"-1";
      var code = process.env.VUE_APP_API_ROOT + EdocService.retrieveImg(identifier);

      //var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BO_PRO_API + projectService.imgResponse(identifier);
      //console.log(code);
      return code;

    },
    uploadImg: async function (file, proj) {
      var identifier = proj;
      let formData = new FormData();
      formData.append('file', document.getElementById("fileUploadProject").files[0]);
      //console.log(identifier);
      //console.log(file);
      //const file = event.target.files[0];

      var response = await projectService.imgUpload(identifier, formData);
      //var responseEdoc = await EdocService.imgUpload(identifier, formData);
      //console.log(response); // 10
      //console.log(responseEdoc); // 10
      if (response) {
        var el = document.getElementById("retrieveImg0");
        el.src = this.retrieveImg(identifier);
        this.$vs.notify({
              title:'Actualizado',
              text:'La imagen ha sido cargada con éxito',
              color:'primary'});
      } else {
        this.$vs.notify({
          title:'Error',
          text:'Ha ocurrido un problema con el formato del archivo',
          color:'danger'});
      }
    },
    uploadImgFirst: async function (file, proj) {
      var identifier = proj+"-1";
      let formData = new FormData();
      formData.append('file', document.getElementById("fileUploadProject1").files[0]);

      var response = await projectService.imgUpload(identifier, formData);
      //console.log(response); // 10
      if (response) {
        var el = document.getElementById("retrieveImg1");
        el.src = this.retrieveImg(identifier);
        this.$vs.notify({
              title:'Actualizado',
              text:'La imagen ha sido cargada con éxito',
              color:'primary'});
      } else {
        this.$vs.notify({
          title:'Error',
          text:'Ha ocurrido un problema con el formato del archivo',
          color:'danger'});
      }
    },
    uploadImgSecond: async function (file, proj) {
      var identifier = proj+"-2";
      let formData = new FormData();
      formData.append('file', document.getElementById("fileUploadProject2").files[0]);

      var response = await projectService.imgUpload(identifier, formData);
      //console.log(response); // 10
      if (response) {
        var el = document.getElementById("retrieveImg2");
        el.src = this.retrieveImg(identifier);
        this.$vs.notify({
              title:'Actualizado',
              text:'La imagen ha sido cargada con éxito',
              color:'primary'});
      } else {
        this.$vs.notify({
          title:'Error',
          text:'Ha ocurrido un problema con el formato del archivo',
          color:'danger'});
      }
    },
    uploadImgThird: async function (file, proj) {
      var identifier = proj+"-3";
      let formData = new FormData();
      formData.append('file', document.getElementById("fileUploadProject3").files[0]);


      var response = await projectService.imgUpload(identifier, formData);
      //console.log(response); // 10
      if (response) {
        var el = document.getElementById("retrieveImg3");
        el.src = this.retrieveImg(identifier);
        this.$vs.notify({
              title:'Actualizado',
              text:'La imagen ha sido cargada con éxito',
              color:'primary'});
      } else {
        this.$vs.notify({
          title:'Error',
          text:'Ha ocurrido un problema con el formato del archivo',
          color:'danger'});
      }
    },
    uploadUnits: async function (file, identifier) {
      //console.log('uploadUnits');
      let formData = new FormData();
      formData.append('file', document.getElementById("fileUploadProjectUnits").files[0]);


      var response = await projectService.uploadUnits(identifier, formData);
      //console.log(response); // 10
      if (response) {
        this.$vs.notify({
              title:'Actualizado',
              text:'El proyecto ha sido actualizada y se verá reflejado al refrescar',
              color:'primary'});
      } else {
        this.$vs.notify({
          title:'Error',
          text:'Ha ocurrido un problema con el formato del archivo',
          color:'danger'});
      }
 
    },

    initValues() {
      if(this.data.id) return
        this.dataId = null
        this.dataName = ""
        this.dataCategory = null
        this.dataOrder_status = "pending"
        this.dataPrice = 0
        this.dataImg = null
    },
    submitData() {
      //console.log("submitData");
      //this.$validator.validateAll().then(result => {
          //if (this.dataId) {
            const obj = {
              id: this.dataId,
              name: this.dataName,
              address: this.dataAddress,
              type: this.dataType,
              status: this.dataStatus,
              bookingAmount : this.dataBookingAmount,
              projectFeatures: this.dataProjectFeatures,
              unitsFeatures: this.dataUnitsFeatures,
              locationFeatures: this.dataLocationFeatures,
              floors: this.dataFloors,
              quotesNumber: this.dataQuotesNumber,
              footPercentage: this.dataFootPercentage,
              quotationNotes: this.dataQuotationNotes,
              email: this.dataEmail,

              projectDaysBooking:this.dataProjectDaysBooking,
              projectBookingParam:this.dataProjectBookingParam,

              paymentAlternative:this.dataPaymentAlternative,
              civilSocietyName:this.dataCivilSocietyName,
              civilSocietyRut:this.dataCivilSocietyRut,
              civilSocietyRepresentativeName1: this.dataCivilSocietyRepresentativeName1,
              civilSocietyRepresentativeRut1: this.dataCivilSocietyRepresentativeRut1,
              civilSocietyRepresentativeDetails1: this.dataCivilSocietyRepresentativeDetails1,
              civilSocietyRepresentativeName2: this.dataCivilSocietyRepresentativeName2,
              civilSocietyRepresentativeRut2: this.dataCivilSocietyRepresentativeRut2,
              civilSocietyRepresentativeDetails2: this.dataCivilSocietyRepresentativeDetails2,
              detailFirstParamArchive: this.dataDetailFirstParamArchive,
              detailBackgroundBuildingInfo: this.dataDetailBackgroundBuildingInfo,
              detailBackgroundBuildingData: this.dataDetailBackgroundBuildingData,
              detailThirdParamArchive: this.dataDetailThirdParamArchive,
              detailInsuranceName: this.dataDetailInsuranceName,
              detailWritingData: this.dataDetailWritingData,
              civilSocietyRepresentativesAddress: this.dataCivilSocietyRepresentativesAddress,
              civilSocietyCity: this.dataCivilSocietyCity,
              signersArray: this.dataSignersArray,
              
              projectProgressPercentage: this.dataProjectProgressPercentage,
              projectBuildingStart: this.dataProjectBuildingStart,
              projectBuildingFinish: this.dataProjectBuildingFinish,
              projectEstimatedSigning: this.dataProjectEstimatedSigning,
              projectBuildingEventNote: this.dataProjectBuildingEventNote,
              projectBuildingEventObservation: this.dataProjectBuildingEventObservation
            }
            //console.log(this.dataId);
            //console.log(obj);
            if (this.dataName && 
                this.dataAddress && 
                this.dataType && 
                this.dataStatus && 
                this.dataBookingAmount && 
                this.dataFloors && 
                this.dataQuotesNumber && 
                this.dataFootPercentage) {
              if(this.dataId !== null) {
                //onsole.log("update run");
                this.$store.dispatch("dataListProject/updateProject", obj).then(() => { /*.catch(err => { console.error(err) })*/
                    //console.info('unidad actualizada');
                    this.$vs.notify({
                          title:'Actualizado',
                          text:'El proyecto ha sido actualizada y se verá reflejado al refrescar',
                          color:'primary'});
                  })
              } else {
                //console.log("new run");
                this.$store.dispatch("dataListProject/newProject", obj).then(() => { /*.catch(err => { console.error(err) })*/
                    //console.info('unidad guardada');
                    this.$vs.notify({
                          title:'Guardado',
                          text:'El proyecto ha sido guardado y se verá reflejado al refrescar',
                          color:'primary'});
                  })             
              }
              this.isSidebarActiveLocal = false;
            } else {
                this.$vs.notify({
                  title:'Error',
                  text:'Ha ocurrido un problema guardando el registro',
                  color:'danger'});
            }

            //this.$emit('closeSidebar')
            //this.initValues()
          //}
      //})
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  components: {
    VuePerfectScrollbar,
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
