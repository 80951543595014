
import projectAPI from '@/APIs/boRealEstateProjectAPI'

const state = {
  milestones: [],
};

const getters = {

};

const actions = {
  /**
   * Llama al servicio de listado de aplicaciones milestone Milestone
   */
  listMilestone({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      projectAPI.get(url, params)
        .then((response) => {
          commit('SET_MILESTONES', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // Update
  updateMilestone({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      projectAPI.put(`/update-milestone/${item.projectId}/${item.milestoneId}`, item )
        .then((response) => {
          commit('UPDATE_MILESTONE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Update
  newMilestone({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    return new Promise((resolve, reject) => {
      projectAPI.post(`/new-milestone/${item.projectId}`, item )
        .then((response) => {
          commit('UPDATE_MILESTONE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Borrar
  removeMilestone({ commit }, item) {
    return new Promise((resolve, reject) => {
      projectAPI.delete(`/delete-milestone/${item.projectId}/${item.id}`)
        .then((response) => {
          commit('REMOVE_MILESTONE', item.id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
  
};

const mutations = {

  SET_MILESTONE(state, milestones) {
    state.milestones = milestones
  },
  UPDATE_MILESTONE(state, milestone) {
    const milestoneIndex = state.milestones.findIndex((p) => p.id == milestone.id)
    Object.assign(state.milestones[milestoneIndex], milestone)
  },
  REMOVE_MILESTONE(state, itemId) {
    const milestoneIndex = state.milestones.findIndex((p) => p.id == itemId)
    state.milestones.splice(milestoneIndex, 1)
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
