<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <project-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <unit-view-sidebar :isSidebarActive="addNewDataSidebarUnit" @closeSidebar="toggleDataSidebarUnit" :data="sidebarDataUnit" />
    <additional-view-sidebar :isSidebarActive="addNewDataSidebarAdditional" @closeSidebar="toggleDataSidebarAdditional" :data="sidebarDataAdditional" />
    <milestone-view-sidebar :isSidebarActive="addNewDataSidebarMilestone" @closeSidebar="toggleDataSidebarMilestone" :data="sidebarDataMilestone" />
    <picture-view-sidebar :isSidebarActive="addNewDataSidebarPicture" @closeSidebar="toggleDataSidebarPicture" :data="sidebarDataPicture" />
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="projects">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        </div>
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ projects.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : projects.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=5">
              <span>5</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <vs-th sort-key="project">Proyecto</vs-th>
        <vs-th sort-key="quoteUnit">Tipo</vs-th>
        <vs-th sort-key="client">Pisos</vs-th>
        <vs-th sort-key="quotationTime">Fecha</vs-th>
        <vs-th sort-key="status">Cuotas</vs-th>
        <vs-th>Acciones</vs-th>
        <vs-th>Nuevo 
          <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="addNewData()" />
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              <p class="product-name font-medium truncate">{{ tr.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{translateType(tr.type)}}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.floors }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.creationDate | formatDate }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.quotesNumber}}</p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <p>Editar</p>
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
            </vs-td>
            <vs-td class="whitespace-no-wrap">
            </vs-td>
              <template slot="expand">
                <div class="vx-row">
                  <!-- Product Image -->
                  <template v-if="dataImg">
                    <!-- Image Container -->
                    <div class="img-container w-64 mx-auto flex items-center justify-center">
                      <img :src="dataImg" alt="img" class="responsive">
                    </div>
                    <!-- Image upload Buttons -->
                    <div class="modify-img flex justify-between mt-5">
                      <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                      <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Update Image</vs-button>
                      <vs-button type="flat" color="#999" @click="dataImg = null">Remove Image</vs-button>
                    </div>
                  </template>
                  <!--Datos del Proyecto -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Datos del Proyecto
                  </vs-divider>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Nombre:</label>
                    <br>
                    {{tr.name}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Dirección:</label>
                    <br>
                    {{tr.address.street}}, {{tr.address.number}}. {{tr.address.region}}. {{tr.address.aditionalInfo}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Pisos:</label>
                    <br>
                    {{tr.floors}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Numero de Cuotas:</label>
                    <br>
                    {{tr.quotesNumber}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Estado:</label>
                    <br>
                    {{translateProjectStatus(tr.status)}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Tipo:</label>
                    <br>
                    {{translateType(tr.type)}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Valor de Reserva:</label>
                    <br>
                    {{tr.bookingAmount| numeral('$0, 0.00')}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Numero de Cuotas:</label>
                    <br>
                    {{tr.quotesNumber}}
                  </div>

                  <!--Datos de Unidades -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Datos de Unidades
                    <br>
                  </vs-divider>


                  <div class="vx-col w-full mt-5">
                    <vs-tabs>
                      <!--<vs-tab label="Unidades">

                        <div class="vx-col md:w-1/5 w-full mt-5" style="float: left;" :data="unitsData" :key="indexUnits" v-for="(unitsData, indexUnits) in tr.units">
                          <label>{{unitsData.description}}</label>
                          <br>
                          {{unitsData.typology}} {{unitsData.type}}
                          <br>
                          <vs-chip :color="getOrderStatusColor(unitsData.status)" class="product-order-status">{{translateStatus(unitsData.status)}}</vs-chip>
                          <br>
                          UF {{unitsData.price| numeral('0, 0.00')}}
                          <br>
                          <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editDataUnit(unitsData, tr.id)" />
                        </div>

                      </vs-tab>-->
                      <vs-tab label="Unidades">
                        <vs-table v-model="selected" pagination max-items="10" search :data="tr.units">
                          <template slot="thead">
                            <vs-th sort-key="description">Unidad</vs-th>
                            <vs-th sort-key="typology">Tipo</vs-th>
                            <vs-th sort-key="status">Estado</vs-th>
                            <vs-th sort-key="price">Precio</vs-th>
                            <vs-th>Exportar 
                              <feather-icon icon="DownloadIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="exportToExcel(tr.units)" />
                            </vs-th>
                          </template>
                          <template slot-scope="{data}">
                            <vs-tr :data="unitsData" :key="indexUnits" v-for="(unitsData, indexUnits) in data">
                              <vs-td :data="data[indexUnits].description">
                                {{data[indexUnits].description}}
                              </vs-td>
                              <vs-td :data="data[indexUnits].typology">
                                {{data[indexUnits].typology}} {{data[indexUnits].type}}
                              </vs-td>
                              <vs-td :data="data[indexUnits].status">
                                <vs-chip :color="getOrderStatusColor(data[indexUnits].status)" class="product-order-status">{{translateStatus(data[indexUnits].status)}}</vs-chip>
                              </vs-td>
                              <vs-td :data="data[indexUnits].price">
                                UF {{data[indexUnits].price| numeral('0, 0.00')}}
                              </vs-td>
                              <vs-td :data="data[indexUnits].unitNumber">
                                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editDataUnit(data[indexUnits], tr.id)" />
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                      </vs-tab>
                      <vs-tab label="Estacionamiento/bodega">
                        <vs-table v-model="selected" pagination max-items="10" search :data="tr.additionals">
                          <template slot="thead">
                            <vs-th sort-key="description">Unidad</vs-th>
                            <vs-th sort-key="description">Tipo</vs-th>
                            <vs-th sort-key="description">Estado</vs-th>
                            <vs-th sort-key="description">Precio</vs-th>
                            <vs-th>Exportar 
                              <feather-icon icon="DownloadIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="exportToExcelAdditionals(tr.additionals)" />
                            </vs-th>
                          </template>
                          <template slot-scope="{data}">
                            <vs-tr :data="additionalsData" :key="indexAdditionals" v-for="(additionalsData, indexAdditionals) in data">
                              <vs-td :data="data[indexAdditionals].description">
                                {{data[indexAdditionals].description}}
                              </vs-td>
                              <vs-td :data="data[indexAdditionals].type">
                                {{translateAdditional(data[indexAdditionals].type)}}
                              </vs-td>
                              <vs-td :data="data[indexAdditionals].status">
                                <vs-chip :color="getOrderStatusColor(data[indexAdditionals].status)" class="product-order-status">{{translateStatus(data[indexAdditionals].status)}}</vs-chip>
                              </vs-td>
                              <vs-td :data="data[indexAdditionals].price">
                                UF {{data[indexAdditionals].price| numeral('0, 0.00')}}
                              </vs-td>
                              <vs-td :data="data[indexAdditionals].unitNumber">
                                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editDataAdditional(data[indexAdditionals], tr.id)" />
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                      </vs-tab>


                      <!--Datos hitos -->
                      <vs-tab label="Hitos de Proyecto">
                        <vs-table v-model="selected" pagination max-items="10" search :data="tr.milestones">
                          <template slot="thead">
                            <vs-th sort-key="description">Hito</vs-th>
                            <vs-th sort-key="description">Descripcion</vs-th>
                            <vs-th sort-key="description">Fecha</vs-th>
                            <vs-th sort-key="description"></vs-th>
                          </template>
                          <template slot-scope="{data}">
                            <vs-tr :data="milestonesData" :key="indexMilestones" v-for="(milestonesData, indexMilestones) in data">
                              <vs-td :data="data[indexMilestones].title">
                                {{data[indexMilestones].title}}
                              </vs-td>
                              <vs-td :data="data[indexMilestones].description">
                                {{data[indexMilestones].description}}
                              </vs-td>
                              <vs-td :data="data[indexMilestones].dateMilestone">  
                                <p class="product-category">{{data[indexMilestones].dateMilestone}}</p>
                              </vs-td>
                              <vs-td :data="data[indexMilestones].id">
                                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editDataMilestone(data[indexMilestones], tr.id, data[indexMilestones].id)" />
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                      </vs-tab>
                      <!--Datos hitos -->

                      <!--Datos imagenes -->
                      <vs-tab label="Imagenes de Proyecto">
                        <vs-table v-model="selected" pagination max-items="10" search :data="tr.pictures">
                          <template slot="thead">
                            <vs-th sort-key="description">Titulo</vs-th>
                            <vs-th sort-key="description">Imagen</vs-th>
                            <vs-th sort-key="description"></vs-th>
                          </template>
                          <template slot-scope="{data}">
                            <vs-tr :data="picturesData" :key="indexPictures" v-for="(picturesData, indexPictures) in data">
                              <vs-td :data="data[indexPictures].title">
                                {{data[indexPictures].title}}
                              </vs-td>
                              <vs-td :data="data[indexPictures].id">
                                  <img id="retrievePicture" :src="retrievePicture(tr.id,data[indexPictures].id)" alt="content-img" class="responsive rounded-lg">
                              </vs-td>
                              <vs-td :data="data[indexPictures].id">
                                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editDataPicture(data[indexPictures], tr.id)" />
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                      </vs-tab>
                      <!--Datos hitos -->


                    </vs-tabs>
                  </div>
                  <!--Datos Adicionales -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Datos Adicionales
                  </vs-divider>
                  <div class="vx-col md:w-1/3 sm:w-full mt-5">
                    <vx-card title="Características de Proyecto" subtitle="">
                      <ul>
                        <li v-for="(line, id) in tr.projectFeatures.split('\n')" :key="id">
                          {{line}}
                        </li>
                      </ul>
                    </vx-card>
                  </div>
                  <div class="vx-col md:w-1/3 sm:w-full mt-5">
                    <vx-card title="Características de las Unidades" subtitle="">
                      <ul>
                        <li v-for="(line, id) in tr.unitsFeatures.split('\n')" :key="id">
                          {{line}}
                        </li>
                      </ul>
                    </vx-card>
                  </div>
                  <div class="vx-col md:w-1/3 sm:w-full mt-5">
                    <vx-card title="Características de la Ubicación" subtitle="">
                      <ul>
                        <li v-for="(line, id) in tr.locationFeatures.split('\n')" :key="id">
                          {{line}}
                        </li>
                      </ul>
                    </vx-card>
                  </div>
                  <!--Datos Adicionales -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Acciones
                  </vs-divider>
                  <div class="vx-col md:w-1/5 w-full mt-5">
                    <label>Editar Proyecto: </label>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" />
                  </div>
                  <div class="vx-col md:w-1/4 w-full mt-5">
                    <label>Eliminar Proyecto: </label>
                    <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteProject(tr.id)" />
                  </div>
                  <div class="vx-col md:w-1/4 w-full mt-5">
                    <label> Nueva Unidad</label> 
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="addNewDataUnit(tr.id)" />
                  </div>
                  <div class="vx-col md:w-1/5 w-full mt-5">
                    <label> Nuevo Adicional</label>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="addNewDataAdditional(tr.id)" />
                  </div>
                  <div class="vx-col md:w-1/5 w-full mt-5">
                    <label> Nuevo hito</label>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="addNewDataMilestone(tr.id)" />
                  </div>
                  <div class="vx-col md:w-1/5 w-full mt-5">
                    <label> Nueva Imagen</label>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="addNewDataPicture(tr.id)" />
                  </div>
                </div>
              </template>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>
<script>
import ProjectViewSidebar from '@/views/ui-elements/data-list/ProjectViewSidebar.vue'
import UnitViewSidebar from '@/views/ui-elements/data-list/UnitViewSidebar.vue'
import AdditionalViewSidebar from '@/views/ui-elements/data-list/AdditionalViewSidebar.vue'
import MilestoneViewSidebar from '@/views/ui-elements/data-list/MilestoneViewSidebar.vue'
import PictureViewSidebar from '@/views/ui-elements/data-list/PictureViewSidebar.vue'
import moduleProject from "@/store/project/moduleProject.js"
import moduleUnit from "@/store/unit/moduleUnit.js"
import EdocService from '@/services/EdocService';
import moduleAdditional from "@/store/additional/moduleAdditional.js"
import moduleMilestone from "@/store/milestone/moduleMilestone.js"
import modulePicture from "@/store/picture/modulePicture.js"
import Vue from 'vue';


import vueNumeralFilterInstaller from 'vue-numeral-filter'; 
Vue.use(vueNumeralFilterInstaller, { locale: 'es' });

export default {
  components: {
    ProjectViewSidebar,
    UnitViewSidebar,
    AdditionalViewSidebar,
    MilestoneViewSidebar,
    PictureViewSidebar
  },
  data() {
    return {
      dataImg: null,
      selected: [],
      // products: [],
      itemsPerPage: 5,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      addNewDataSidebarUnit: false,
      addNewDataSidebarAdditional: false,
      addNewDataSidebarMilestone: false,
      addNewDataSidebarPicture: false,
      sidebarData: {},
      sidebarDataUnit: {},
      sidebarDataAdditional: {},
      sidebarDataMilestone: {},
      sidebarDataPicture: {},
      currency: {},
      
      fileName: "",
      formats:["xlsx", "csv", "txt"] ,
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["Descripcion", "Numero", "Tipologia", "Tipo", "Habitaciones", "Banos", "Piso", "Orientacion", "Precio", "Estado", "Interior", "Terraza", "Total"],
      headerVal: ["description", "unitNumber", "typology", "type", "rooms", "bathrooms", "floor", "orientation", "price", "status", "interiorSize", "terraceSize", "totalSize"],
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    projects() {
      return this.$store.state.dataListProject.projects
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.projects.length
    },
    isFormValid() {
      return !this.errors.any() && this.name && this.floors && (this.quotesNumber > 0)
    }
  },
  methods: {
    exportToExcel(data) {
      
      this.$log.debug('data: ',data);
      var unitsVar = data
      for (var i = unitsVar.length - 1; i >= 0; i--) {
        unitsVar[i].description = unitsVar[i].description
        unitsVar[i].unitNumber = unitsVar[i].unitNumber
        unitsVar[i].typology = unitsVar[i].typology
        unitsVar[i].type = unitsVar[i].type
        unitsVar[i].totalSize = unitsVar[i].totalSize
        unitsVar[i].terraceSize = unitsVar[i].terraceSize
        unitsVar[i].interiorSize = unitsVar[i].interiorSize
        unitsVar[i].status = unitsVar[i].status
        unitsVar[i].rooms = unitsVar[i].rooms
        unitsVar[i].price = unitsVar[i].price
        unitsVar[i].orientation = unitsVar[i].orientation
        unitsVar[i].description = unitsVar[i].description
        unitsVar[i].status = unitsVar[i].status
      }
      import('@/export/Export2Excel').then(excel => {
        const list = unitsVar
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        //this.clearFields()
      })
    },
    exportToExcelAdditionals(data) {
      
      this.$log.debug('data: ',data);
      var unitsVar = data
      for (var i = unitsVar.length - 1; i >= 0; i--) {
        unitsVar[i].description = unitsVar[i].description
        unitsVar[i].unitNumber = unitsVar[i].unitNumber
        unitsVar[i].typology = unitsVar[i].typology
        unitsVar[i].type = unitsVar[i].type
        unitsVar[i].totalSize = unitsVar[i].totalSize
        unitsVar[i].terraceSize = unitsVar[i].terraceSize
        unitsVar[i].interiorSize = unitsVar[i].interiorSize
        unitsVar[i].status = unitsVar[i].status
        unitsVar[i].rooms = unitsVar[i].rooms
        unitsVar[i].price = unitsVar[i].price
        unitsVar[i].orientation = unitsVar[i].orientation
        unitsVar[i].description = unitsVar[i].description
        unitsVar[i].status = unitsVar[i].status
      }
      import('@/export/Export2Excel').then(excel => {
        const list = unitsVar
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        //this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    addNewDataUnit(projectId) {
      //console.log(projectId);
      this.sidebarDataUnit = {"projectId": projectId, "currency": this.currency, floor:1, rooms:1, bathrooms:1, status: "AVAILABLE", orientation:"NORTH" }
      this.toggleDataSidebarUnit(true)
    },
    addNewDataAdditional(projectId) {
      //console.log(projectId);
      var currency = this.$store.state.dataListProject.projects[0].units[0].currency
      this.sidebarDataAdditional = {"projectId": projectId, "currency": currency, status: "AVAILABLE", type: "parking"}
      this.toggleDataSidebarAdditional(true)
    },
    addNewDataMilestone(projectId) {
      //console.log(projectId);
      this.sidebarDataMilestone = {"projectId": projectId}
      this.toggleDataSidebarMilestone(true)
    },
    addNewDataPicture(projectId) {
      //console.log(projectId);
      this.sidebarDataPicture = {"projectId": projectId}
      this.toggleDataSidebarPicture(true)
    },
    addNewData() {
      this.sidebarData = ""
      this.toggleDataSidebar(true)
    },
    deleteProject(id) {
      this.$store.dispatch("dataListProject/removeProject", id)//.catch(err => { console.error(err) })
    },
    updateProject(data) {
      this.$store.dispatch('updateProject', data)//.catch(err => { console.error(err) })
    },
    editData(data) {
      //this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    editDataUnit(data, projectId) {
      //this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      //project ID not set
      data["projectId"] = projectId;
      data["unitId"] = projectId;
      this.sidebarDataUnit = data
      this.toggleDataSidebarUnit(true)
    },
    editDataAdditional(data, projectId) {
      data["projectId"] = projectId;
      data["unitId"] = projectId;
      this.sidebarDataAdditional = data
      this.toggleDataSidebarAdditional(true)
    },
    editDataMilestone(data, projectId,milestoneId) {
      data["projectId"] = projectId;
      data["id"] = milestoneId;
      this.sidebarDataMilestone = data
      this.toggleDataSidebarMilestone(true)
    },
    editDataPicture(data, projectId) {
      data["projectId"] = projectId;
      this.sidebarDataPicture = data
      this.toggleDataSidebarPicture(true)
    },
    retrievePicture(proj, pictureId) {
      var identifier = "img_"+proj+pictureId;
      var code = process.env.VUE_APP_API_ROOT + EdocService.retrieveImg(identifier);

      //var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BO_PRO_API + projectService.imgResponse(identifier);
      //console.log(code);
      return code;

    },
    translateType(type) {
      if (type == 'APARTMENTS') return "Departamentos"
      if (type == 'HOUSEs') return "Casas"
      if (type == 'OFFICES') return "Oficinas"
      if (type == 'MIXED') return "Mixtos"
      return "Desconocido"
    },
    translateAdditional(type) {
      if (type == 'parking') return "Estacionamiento"
      if (type == 'warehouse') return "bodega"
      if (type == 'parkingWarehouse') return "Estacionamiento con Bodega"
      return "No Aplica"
    },
    getOrderStatusColor(status) {
      if (status == 'AWAITING_BOOKING_PAYMENT') return "warning"
      if (status == 'BOOKED') return "success"
      if (status == 'AVAILABLE') return "primary"
      if (status == 'SOLD') return "danger"
      if (status == 'BLOCKED') return "danger"
      return "primary"
    },
    translateStatus(status) {
      if (status == 'AWAITING_BOOKING_PAYMENT') return "En espera de pago"
      if (status == 'BOOKED') return "Reservada"
      if (status == 'AVAILABLE') return "Disponible"
      if (status == 'SOLD') return "Vendido"
      if (status == 'BLOCKED') return "Bloqueado"
      return "Indeterminado"
    },
    translateProjectStatus(status) {
      if (status == 'ACTIVE') return "Activo"
      if (status == 'INACTIVE') return "Inactivo"
      return "Indeterminado"
    },
    submitData(data) {
      //this.$validator.validateAll().then(result => {
      if (data) {
        const obj = {
          id: data.id,
          name: data.name,
          address: data.address,
          type: data.type,
          status: data.status,
          bookingAmount: data.bookingAmount,
          projectFeatures: data.projectFeatures,
          unitsFeatures: data.unitsFeatures,
          locationFeatures: data.locationFeatures,
          floors: data.floors,
          quotesNumber: data.quotesNumber
        }
        //console.info(obj);

        //if(data.id !== null && data.id >= 0) {
        this.$store.dispatch("dataListProject/updateItem", obj)//.catch(err => { console.error(err) })
        //}

        //this.$emit('closeSidebar')
        //this.initValues()
      }
      //})
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    toggleDataSidebarUnit(val = false) {
      this.addNewDataSidebarUnit = val
    },
    toggleDataSidebarAdditional(val = false) {
      this.addNewDataSidebarAdditional = val
    },
    toggleDataSidebarMilestone(val = false) {
      this.addNewDataSidebarMilestone = val
    },
    toggleDataSidebarPicture(val = false) {
      this.addNewDataSidebarPicture = val
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  created() {
    if (!moduleProject.isRegistered) {
      this.$store.registerModule('dataListProject', moduleProject)
      moduleProject.isRegistered = true
    }
    if (!moduleUnit.isRegistered) {
      this.$store.registerModule('dataListUnit', moduleUnit)
      moduleUnit.isRegistered = true
    }
    if (!moduleAdditional.isRegistered) {
      this.$store.registerModule('dataListAdditional', moduleAdditional)
      moduleAdditional.isRegistered = true
    }
    if (!moduleMilestone.isRegistered) {
      this.$store.registerModule('dataListMilestone', moduleMilestone)
      moduleMilestone.isRegistered = true
    }
    if (!modulePicture.isRegistered) {
      this.$store.registerModule('dataListPicture', modulePicture)
      modulePicture.isRegistered = true
    }
    let pageObject = {
      page: 0,
      size: 100
    };
    this.$store.dispatch("dataListProject/listProjects", pageObject)
  },
  mounted() {
    this.isMounted = true;
    //console.log("projects");
    //console.log(this.$store.state.dataListProject.projects);
    this.currency = this.$store.state.dataListProject.projects[0].units[0].currency;
    //console.log(this.currency);
  }
}

</script>
<style lang="scss">

#retrievePicture{  width: 100px;  height: auto; } 
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

</style>
