/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_ADDITIONAL(state, additionals) {
    state.additionals = additionals
  },
  UPDATE_ADDITIONAL(state, project) {
    //console.log("update project mutations");
    //console.log(project);
    //console.log(state);
    const projectIndex = state.projects.findIndex((p) => p.id == project.id)
    Object.assign(state.projects[projectIndex], project)
    /*
    const additionalIndex = state.additionals.findIndex((p) => p.unitNumber == additional.unitNumber)
    Object.assign(state.additionals[additionalIndex], additional)*/
  },
  CREATE_ADDITIONAL(state, project) {
    const projectIndex = state.projects.findIndex((p) => p.id == project.id)
    Object.assign(state.projects[projectIndex], project)
  },
  REMOVE_ADDITIONAL(state, itemId) {
    const ItemIndex = state.additionals.findIndex((p) => p.id == itemId)
    state.additionals.splice(ItemIndex, 1)
  },
}
